import Header from '../components/Header'
import Footer from '../components/Footer'
import {memo, useEffect} from "react"
import dynamic from 'next/dynamic'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Page404 = dynamic(() => import('../components/Page404Content'))

const Page404Content = () => {
	useEffect(() => {
		Aos.init()
	}, [])

	return (
		<>
			<Header title='Metaisland - 404 Page Not Found' />
			<Page404/>
			<Footer/>
		</>
	)
}

export default memo(Page404Content)
